'use client';

import { ZigZagData } from '@sapientpro/sapientpro-data-models';
import React from 'react';
import AnimatedTitle, { AnimationType } from '../../../components/AnimatedTitle';
import Img from '../../../components/Img';
import styles from './InfoCard.module.scss';

type InfoCardProps = {
  data: ZigZagData,
};

const InfoCard = ({ data }: InfoCardProps) => (
  <article className={styles.infoCard}>
    <div className={styles.image}>
      <Img
        src={data.image ? data.image.url : '/'}
        alt={data?.image?.alternativeText || data.title}
        withPreloader
      />
    </div>
    <div className={styles.content}>
      <header>
        <h3 className={styles.subTitle}>{data.subTitle}</h3>
        <h2 className={styles.title}>
          <AnimatedTitle
            animationType={AnimationType.NONE}
            title={data.title}
          />
        </h2>
      </header>
      <div
        className={styles.description}
        dangerouslySetInnerHTML={{ __html: data.description }}
      />
    </div>
  </article>
);

export default InfoCard;
