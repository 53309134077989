'use client';

import { IProjectDetailsComponent } from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';
import { useAtomValue } from 'jotai';
import React from 'react';
import AnimatedTitle from '../../../../components/AnimatedTitle';
import Img from '../../../../components/Img';
import { theme } from '../../../../store/theme';
import styles from './ProjectDetails.module.scss';

type ProjectDetailsProps = {
  data: IProjectDetailsComponent
};
const ProjectDetails = ({ data }: ProjectDetailsProps) => {
  const appTheme = useAtomValue(theme);

  return (
    <section className={classnames(styles.details, 'container', {
      [styles.simple]: !data.technology,
    })}
    >
      <header className={styles.header}>
        <h4 className={classnames('title4', styles.title)}><AnimatedTitle title={data.title} /></h4>
        {data.description && (
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: data.description }}
          />
        )}
      </header>
      {data.image && (
        <div className={styles.image}>
          <Img
            width={data.image.width || 1040}
            height={data.image.height || 526}
            src={data.image.url}
            alt={data.image.alternativeText || data.title}
            withPreloader
          />
        </div>
      )}
      {data?.technology?.technologies?.length && (
        <div className={styles.technologies}>
          <div className={styles.technologies__content}>
            <h4 className={classnames('title6', styles.technologies__title)}>{data.technology.title}</h4>
            <p className={styles.technologies__text}>
              {data.technology.description}
            </p>
          </div>
          <div className={styles.technologies__items__wrap}>
            <div className={styles.technologies__items}>
              {data.technology.technologies.map(item => (
                <div
                  className={styles.technology}
                  key={item.id}
                >
                  <div className={styles.technology__icon}>
                    <img
                      src={item.image?.[appTheme].url}
                      alt={item.image?.[appTheme].alternativeText || 'technology icon'}
                    />
                  </div>
                  <p className={styles.technology__title}>{item.name}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default ProjectDetails;
