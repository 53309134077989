import classnames from 'classnames';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { useState } from 'react';
import Button, { ButtonVariants } from '../../components/Button';
import contactModalTriggerButton from '../../store/contactForm';
import clientDeviceSize, { ScreenSize } from '../../store/deviceScreenSize';
import styles from './Subservice.module.scss';

type MicroserviceCardProps = {
  title: string,
  text: string,
  visuals: {
    big: string,
    medium: string,
    small: string,
  };
  visualsType: number,
};
const MicroserviceCard = ({
  title, text, visuals, visualsType,
}: MicroserviceCardProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const deviceSize = useAtomValue<ScreenSize>(clientDeviceSize);
  const setContactsModalTrigger = useSetAtom(contactModalTriggerButton);

  return (
    <div
      className={classnames(styles.serviceCard, {
        [styles.active]: isHovered,
      })}
      onMouseEnter={() => deviceSize >= ScreenSize.DESKTOP_SMALL && setIsHovered(true)}
      onMouseLeave={() => deviceSize >= ScreenSize.DESKTOP_SMALL && setIsHovered(false)}
    >
      <div className={styles.serviceCard__content}>
        <h5 className={styles.serviceCard__title}>
          .
          {title}
        </h5>
        <p className={styles.serviceCard__text}>{text}</p>
      </div>
      <div className={classnames(styles.serviceCard__visuals, styles[`serviceCard__visuals_${visualsType}`])}>
        <Button
          variant={ButtonVariants.OUTLINED}
          className={styles.serviceCard__button}
          icon={(
            <svg>
              <use
                xlinkHref='/media/icon-nest.svg#iconNest'
                href='/media/icon-nest.svg#iconNest'
              />
            </svg>
          )}
          iconPosition='right'
          iconSize={{ width: 24, height: 24 }}
          onClick={(e) => {
            setContactsModalTrigger(`${(e.target as HTMLElement).innerText} | Service Card`);
          }}
        >
          Start now
        </Button>
        <img
          src={visuals.big}
          alt='visual item'
          className={classnames(styles.visualsItem, styles.visualsItem_big)}
        />
        <img
          src={visuals.medium}
          alt='visual item'
          className={classnames(styles.visualsItem, styles.visualsItem_medium)}
        />
        <img
          src={visuals.small}
          alt='visual item'
          className={classnames(styles.visualsItem, styles.visualsItem_small)}
        />
      </div>
    </div>
  );
};

export default MicroserviceCard;
