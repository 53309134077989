import { CaseListItem, ICaseResponse } from '@sapientpro/sapientpro-data-models';
import Link from 'next/link';
import React from 'react';
import AnimatedTitle, { AnimationType } from '../../../../components/AnimatedTitle';
import Img from '../../../../components/Img';
import styles from './CaseItem.module.scss';

const CaseItem = ({ caseItem }: { caseItem: CaseListItem | ICaseResponse }) => {
  const {
    subservices, slug, image,
  } = caseItem;

  return (
    <div className={styles.case}>
      {subservices && subservices.length > 0 && (
        <div className={styles.categories}>
          {subservices.map(subservice => (
            <Link
              href={`/${subservice.slug}`}
              className={styles.category}
              key={subservice.id}
            >
              .
              <AnimatedTitle
                animationType={AnimationType.NONE}
                title={subservice.title}
              />
            </Link>
          ))}
        </div>
      )}
      <Link
        href={`/cases/${slug}`}
        passHref
        className={styles.image}
      >
        <Img
          src={image ? image?.url : '/'}
          alt={image.alternativeText || caseItem.slug}
          withPreloader
        />
      </Link>
    </div>
  );
};

export default CaseItem;
