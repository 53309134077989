'use client';

import React, { useEffect, useMemo, useState } from 'react';
import { IMeetOurTeam, IMemberUs } from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';
import { useAtomValue } from 'jotai';
import Img from '../../components/Img';
import clientDeviceSize, { ScreenSize } from '../../store/deviceScreenSize';
import styles from './CompanyTeam.module.scss';

type TeamMemberProps = {
  data: IMeetOurTeam,
};
const TeamMember = ({ member, className }: { member: IMemberUs, className?: string }) => {
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);
  useEffect(() => {
    setIsImageLoaded(false);
  }, [member]);

  return (
    <div className={classnames(styles.card, className)}>
      <div className={styles.card__photo}>
        {!isImageLoaded && (
          <div className={styles.skeleton} />
        )}
        <Img
          src={member.image.url}
          alt={member.image.alternativeText || member.name}
          onLoad={() => setIsImageLoaded(true)}
          className={classnames(styles.cardImage, {
            [styles.cardImage_visible]: isImageLoaded,
          })}
        />
      </div>
      <p className={styles.card__title}>{member.name}</p>
      <p className={styles.card__text}>{member.position}</p>
    </div>
  );
};

const portraitLimit = 3;
const landscapeLimit = 4;
const desktopLimit = 5;

const CompanyTeam = ({ data }: TeamMemberProps) => {
  const deviceSize = useAtomValue<ScreenSize>(clientDeviceSize);
  const [page, setPage] = useState(0);

  const [startCardAnimation, setStartCardAnimation] = useState(true);

  const membersLimit = useMemo(() => (deviceSize === ScreenSize.TABLET_PORTRAIT ? portraitLimit
    : deviceSize === ScreenSize.TABLET_LANDSCAPE ? landscapeLimit
      : desktopLimit), [deviceSize]);

  const renderedTeam = useMemo(() => (deviceSize > ScreenSize.MOBILE
    ? data.team.members.slice((page * membersLimit), (page * membersLimit) + membersLimit)
    : data.team.members), [deviceSize, page]);

  const isLastPage = useMemo(() => (page * membersLimit + membersLimit) >= data.team.members.length, [page, membersLimit]);

  return (
    <section className={classnames(styles.section, 'container')}>
      <h2 className={classnames(styles.title, 'title3')}>{data.title}</h2>
      <p className={classnames(styles.subtitle, 'bodyText')}>{data.description}</p>
      <div className={styles.team}>
        {deviceSize === ScreenSize.MOBILE && renderedTeam.map(member => (
          <TeamMember
            className={classnames({
              [styles.animate]: startCardAnimation,
            })}
            member={member}
          />
        ))}
        {deviceSize > ScreenSize.MOBILE && (
          <>
            <div className={classnames(styles.column, styles.column_first)}>
              {renderedTeam.slice(0, 1)[0] && renderedTeam.length !== 1 && (
                <TeamMember
                  className={classnames({
                    [styles.animate]: startCardAnimation,
                  })}
                  member={renderedTeam.slice(0, 1)[0]}
                />
              )}
              {renderedTeam.slice(1, 2)[0]
                && ((deviceSize > ScreenSize.TABLET_PORTRAIT && page === 0) || deviceSize === ScreenSize.TABLET_PORTRAIT)
                && !isLastPage
                && (
                  <TeamMember
                    className={classnames({
                      [styles.animate]: startCardAnimation,
                    })}
                    member={renderedTeam.slice(1, 2)[0]}
                  />
                )}
              {page > 0 && (
                <button
                  type='button'
                  className={classnames(styles.moreButton)}
                  onClick={() => {
                    setStartCardAnimation(false);
                    setTimeout(() => setStartCardAnimation(true));
                    setPage(prev => prev - 1);
                  }}
                >
                  <div
                    className={classnames(styles.icon)}
                  >
                    <svg>
                      <use
                        xlinkHref='/media/arrow-left-bold.svg#arrowLeft'
                        href='/media/arrow-left-bold.svg#arrowLeft'
                      />
                    </svg>
                  </div>
                  <p className={styles.moreButton__title}>Back</p>
                </button>
              )}
            </div>
            {deviceSize > ScreenSize.TABLET_PORTRAIT && (
              <div className={classnames(styles.column, styles.column_second)}>
                {page > 0 && renderedTeam.slice(1, 2)[0] && (
                  <TeamMember
                    className={classnames({
                      [styles.animate]: startCardAnimation,
                    })}
                    member={renderedTeam.slice(1, 2)[0]}
                  />
                )}
                {renderedTeam.slice(2, 3)[0] && renderedTeam.length > 3 && (
                  <TeamMember
                    className={classnames({
                      [styles.animate]: startCardAnimation,
                    })}
                    member={renderedTeam.slice(2, 3)[0]}
                  />
                )}
                {renderedTeam.length === 1 && (
                  <TeamMember
                    className={classnames({
                      [styles.animate]: startCardAnimation,
                    })}
                    member={renderedTeam.slice(0, 1)[0]}
                  />
                )}
              </div>
            )}
            {deviceSize > ScreenSize.TABLET_LANDSCAPE && (
              <div className={classnames(styles.column, styles.column_third)}>
                {renderedTeam.slice(3, 4)[0] && (
                  <TeamMember
                    className={classnames({
                      [styles.animate]: startCardAnimation,
                    })}
                    member={renderedTeam.slice(3, 4)[0]}
                  />
                )}
                {renderedTeam.length === 3 && renderedTeam.slice(2, 3)[0] && (
                  <TeamMember
                    className={classnames({
                      [styles.animate]: startCardAnimation,
                    })}
                    member={renderedTeam.slice(2, 3)[0]}
                  />
                )}
              </div>
            )}
            <div className={classnames(styles.column, styles.column_last)}>
              {!isLastPage && (
                <button
                  type='button'
                  className={classnames(styles.moreButton)}
                  onClick={() => {
                    setStartCardAnimation(false);
                    setTimeout(() => setStartCardAnimation(true));
                    setPage(prev => prev + 1);
                  }}
                >
                  <div
                    className={classnames(styles.icon)}
                  >
                    <svg>
                      <use
                        xlinkHref='/media/arrow-right-bold.svg#arrowRight'
                        href='/media/arrow-right-bold.svg#arrowRight'
                      />
                    </svg>
                  </div>
                  <p className={styles.moreButton__title}>See More Employees</p>
                </button>
              )}
              {deviceSize === ScreenSize.TABLET_PORTRAIT && (
                <>
                  {renderedTeam.length === 1 && (
                    <TeamMember
                      className={classnames({
                        [styles.animate]: startCardAnimation,
                      })}
                      member={renderedTeam.slice(0, 1)[0]}
                    />
                  )}
                  {isLastPage && renderedTeam.slice(1, 2)[0] && (
                    <TeamMember
                      className={classnames({
                        [styles.animate]: startCardAnimation,
                      })}
                      member={renderedTeam.slice(1, 2)[0]}
                    />
                  )}
                  {renderedTeam.slice(2, 3)[0] && (
                    <TeamMember
                      className={classnames({
                        [styles.animate]: startCardAnimation,
                      })}
                      member={renderedTeam.slice(2, 3)[0]}
                    />
                  )}
                </>
              )}
              {deviceSize === ScreenSize.TABLET_LANDSCAPE && (
                <>
                  {renderedTeam.length <= 3 && renderedTeam.slice(2, 3)[0] && (
                    <TeamMember
                      className={classnames({
                        [styles.animate]: startCardAnimation,
                      })}
                      member={renderedTeam.slice(2, 3)[0]}
                    />
                  )}
                  {renderedTeam.slice(3, 4)[0] && (
                    <TeamMember
                      className={classnames({
                        [styles.animate]: startCardAnimation,
                      })}
                      member={renderedTeam.slice(3, 4)[0]}
                    />
                  )}
                </>
              )}
              {deviceSize >= ScreenSize.DESKTOP_SMALL && renderedTeam.slice(4, 5)[0] && (
                <TeamMember
                  className={classnames({
                    [styles.animate]: startCardAnimation,
                  })}
                  member={renderedTeam.slice(4, 5)[0]}
                />
              )}
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default CompanyTeam;
