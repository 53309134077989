'use client';

import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';

import { CaseFeature } from '@sapientpro/sapientpro-data-models/src/case/contentoo-case';
import clientDeviceSize, { ScreenSize } from '../../../../store/deviceScreenSize';

import AnimatedTitle from '../../../../components/AnimatedTitle';
import FramedImages from './FramedImages/FramedImages';

import styles from './FeatureBlock.module.scss';

function divideArrayIntoSubarrays<T>(array: T[], numOfSubarrays: number): T[][] {
  let result: T[][] = [];
  let subarraySize: number = Math.ceil(array.length / numOfSubarrays); // Calculate size of each subarray

  for (let i = 0; i < array.length; i += subarraySize) {
    result.push(array.slice(i, i + subarraySize));
  }

  return result;
}

type FeatureBlockContentProps = {
  description: CaseFeature['descriptions'][number],
  framedImagesPosition: CaseFeature['framedImagesPosition'],
  images: CaseFeature['images'],
  setCurrentSlideIndex: React.Dispatch<React.SetStateAction<number>>,
  optionalImage: CaseFeature['optionalImage'],
  scopeRef?: React.MutableRefObject<HTMLDivElement | null>,
  id: number | string;
};

const FeatureBlockContent = ({
  description, framedImagesPosition, images, setCurrentSlideIndex, optionalImage, scopeRef, id,
}: FeatureBlockContentProps) => (
  <>
    <div className={styles.descriptions}>
      <div
        key={description.id}
        dangerouslySetInnerHTML={{ __html: description.description }}
      />
    </div>
    <FramedImages
      className={styles.images}
      framedImagesPosition={framedImagesPosition}
      images={images}
      onMove={setCurrentSlideIndex}
      optionalImageUrl={optionalImage?.image?.url}
      optionalImagePosition={optionalImage?.position}
      scopeRef={scopeRef}
      id={id}
    />
  </>
);

const FeatureBlock = ({
  data: {
    title, images, descriptions, framedImagesPosition, optionalImage, id,
  },
}: { data: CaseFeature }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);
  const currentDescription = descriptions[currentSlideIndex] || descriptions[descriptions.length - 1];
  const scopeRef = useRef<HTMLDivElement | null>(null);
  const deviceSize = useAtomValue<ScreenSize>(clientDeviceSize);

  const isNotDesktop = deviceSize === ScreenSize.MOBILE
                    || deviceSize === ScreenSize.TABLET_PORTRAIT
                    || deviceSize === ScreenSize.TABLET_LANDSCAPE;

  const renderSeparateBlock = isNotDesktop && descriptions.length > 1;
  const slidersImages = divideArrayIntoSubarrays(images.slice(1), descriptions.length - 1);
  const firstBlockImage = images[0];

  return (
    <div ref={scopeRef}>
      <section
        className={classNames(styles.feature, 'container', `trigger-${id}`, {
          [styles.feature__fullWidth]: framedImagesPosition === 'fullWidth',
          [styles.feature__onLeft]: framedImagesPosition === 'left',
          [styles.feature__onRight]: framedImagesPosition === 'right',
          trigger: images.length > 1,
        })}
      >
        <h4 className={classNames('title4', styles.title)}>
          <AnimatedTitle title={title} />
        </h4>
        {renderSeparateBlock ? descriptions.map((description, i) => (
          <div
            key={description.id}
            className={styles.feature__block}
          >
            <FeatureBlockContent
              {...{
                description, framedImagesPosition, setCurrentSlideIndex, optionalImage, scopeRef,
              }}
              id={`${id}-${i}`}
              images={i ? slidersImages[i - 1] : [firstBlockImage]}
            />
          </div>
        ))
          : (
            <FeatureBlockContent
              {...{
                images, framedImagesPosition, setCurrentSlideIndex, optionalImage, scopeRef, id,
              }}
              description={currentDescription}
            />
          )}
      </section>
    </div>
  );
};

export default FeatureBlock;
