'use client';

import React from 'react';
import { IChallenge, IChallengesComponent } from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';
import styles from './Challenges.module.scss';

const Challenge = ({ title, description }: Pick<IChallenge, 'title' | 'description'>) => (
  <div className={styles.challenge}>
    <p className={styles.challenge__title}>{title}</p>
    <p className={styles.challenge__value}>{description}</p>
  </div>
);

type ChallengesProps = {
  data: IChallengesComponent
};
const Challenges = ({ data }: ChallengesProps) => (
  <section className={classnames(styles.challenges, 'container')}>
    <h4 className={classnames('title4', styles.title)}>{data.title}</h4>
    <div className={styles.challenges__content}>
      {data.challenges.map((challenge: IChallenge) => (
        <Challenge
          key={challenge.id}
          title={challenge.title}
          description={challenge.description}
        />
      ))}
    </div>
  </section>
);

export default Challenges;
