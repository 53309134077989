'use client';

import { IStepsBlock } from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';
import { useSetAtom } from 'jotai';
import React, { SyntheticEvent } from 'react';
import AnimatedTitle from '../../components/AnimatedTitle';
import Button, { ButtonVariants } from '../../components/Button';
import contactModalTriggerButton from '../../store/contactForm';
import styles from './HowWeWork.module.scss';
import HowWeWorkItem from './HowWeWorkItem';
import { GAEmit, GAEvents } from '../../gtagHelper';

const HowWeWork = ({ data }: { data: IStepsBlock }) => {
  const setContactsModalTrigger = useSetAtom(contactModalTriggerButton);

  const handleHowWeWorkClick = (event: SyntheticEvent) => {
    setContactsModalTrigger(`${(event.target as HTMLElement).innerText} | How We Work section`);
    GAEmit(GAEvents.startYourProjectClick);
  };

  const buttonText = data.ctaButton || 'start your project';

  return (
    <section className={classnames(styles.howWeWork, 'container')}>
      <div className={styles.howWeWork__info}>
        <h2 className={classnames(styles.howWeWork__title, 'sectionTitle')}>
          <AnimatedTitle title={data.title} />
        </h2>
        <p className={styles.howWeWork__subtitle}>
          {data.description}
        </p>
        <Button
          variant={ButtonVariants.OUTLINED}
          className={styles.howWeWork__button}
          icon={(
            <svg>
              <use
                xlinkHref='/media/icon-nest.svg#iconNest'
                href='/media/icon-nest.svg#iconNest'
              />
            </svg>
          )}
          iconPosition='right'
          iconSize={{ width: 24, height: 24 }}
          size='medium'
          onClick={handleHowWeWorkClick}
        >
          {buttonText}
        </Button>
      </div>
      <div className={styles.howWeWork__content}>
        {data.development_processes.map(item => (
          <HowWeWorkItem
            key={item.id}
            icon={item.image}
            title={item.title}
            text={item.description}
          />
        ))}
      </div>
    </section>
  );
};

export default HowWeWork;
