'use client';

import React from 'react';
import classNames from 'classnames';

import { CaseBaseBlock } from '@sapientpro/sapientpro-data-models/types/case/contentoo-case';

import FramedImages from '../FeatureBlock/FramedImages/FramedImages';

import styles from './DefaultScreenBlock.module.scss';

const DefaultScreenBlock = ({
  data: {
    id,
    image,
    optionalImage,
  },
}: { data: CaseBaseBlock }) => (
  <section className={classNames(styles.wrapper, 'container')}>
    <FramedImages
      id={id}
      framedImagesPosition='fullWidth'
      images={[{ id, image }]}
      optionalImageUrl={optionalImage?.image.url}
      optionalImagePosition={optionalImage.position}
    />
  </section>
);
export default DefaultScreenBlock;
