import Link from 'next/link';
import { ReactNode } from 'react';
import AnimatedTitle, { AnimationType } from '../../components/AnimatedTitle';
import styles from './Service.module.scss';

type ServiceCardProps = {
  icon: string,
  title: string,
  text: string,
  id: number,
  slug?: string;
};

const SubserviceCardTitle = ({ title }: { title: string }) => (
  <h5 className={styles.serviceCard__title}>
    .
    <AnimatedTitle
      title={title}
      animationType={AnimationType.NONE}
    />
  </h5>
);

const CardWrapper = ({ slug, id, children }: { slug?: string, id: number, children: ReactNode }) => (
  slug ? (
    <Link
      className={styles.serviceCard}
      href={`/${slug}`}
    >
      {children}
    </Link>
  ) : (
    <div
      className={styles.serviceCard}
      id={`${id}`}
    >
      {children}
    </div>
  )
);

const SubserviceCard = ({
  icon, title, text, id, slug,
}: ServiceCardProps) => (
  <CardWrapper {...{ id, slug }}>
    <>
      <div className={styles.overlay} />
      <div className={styles.serviceCard__content}>
        <div className={styles.serviceCard__icon}>
          <img
            src={icon}
            alt={slug}
          />
        </div>
        <SubserviceCardTitle title={title} />
        <p className={styles.serviceCard__text}>{text}</p>
      </div>
    </>
  </CardWrapper>
);

export default SubserviceCard;
