'use client';

import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { Splide } from '@splidejs/react-splide';
import { IImage } from '@sapientpro/sapientpro-data-models';
import dynamic from 'next/dynamic';

import { FramedImagesPosition } from '@sapientpro/sapientpro-data-models/types/case/contentoo-case';

import Img from '../../../../../components/Img';
import PhoneClip from './PhoneClip';

import styles from './FramedImages.module.scss';

const ScrollSlider = dynamic(() => import('./ScrollSlider'), { ssr: false });

type FramedImagesProps = {
  images: { id: number; image: IImage }[];
  className?: string;
  optionalImageUrl?: string;
  optionalImagePosition?: 'left' | 'right';
  onMove?: React.Dispatch<React.SetStateAction<number>>
  framedImagesPosition: FramedImagesPosition;
  scopeRef?: React.MutableRefObject<HTMLDivElement | null>,
  id: number | string;
};
const FramedImages = forwardRef<Splide, FramedImagesProps>(({
  images, className, optionalImageUrl, optionalImagePosition, onMove, framedImagesPosition, scopeRef, id,
}) => (
  <section
    className={classNames(styles.screen, styles.screen_tablet, className, {
      [styles.screen_slider]: images?.length > 1,
    })}
  >
    {images?.length > 1 ? (
      !!scopeRef && (
        <ScrollSlider
          {...{
            images, framedImagesPosition, onMove, scopeRef, id,
          }}
        />
      )
    ) : (
      <div className={classNames(styles.screen__content)}>
        <Img
          src={images?.[0]?.image?.url}
          alt={images?.[0]?.image.alternativeText || 'framed image'}
        />
      </div>
    )}
    {optionalImageUrl && (
      <PhoneClip
        className={classNames(styles.optionalImage, {
          [styles.optionalImage_onLeft]: optionalImagePosition === 'left',
        })}
        imageUrl={optionalImageUrl}
      />
    )}
  </section>
));

export default FramedImages;
