'use client';

import { IPartnersComponent } from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { SyntheticEvent } from 'react';
import AnimatedTitle from '../../../components/AnimatedTitle';
import Button, { ButtonVariants } from '../../../components/Button';
import Img from '../../../components/Img';
import contactModalTriggerButton from '../../../store/contactForm';
import clientDeviceSize, { ScreenSize } from '../../../store/deviceScreenSize';
import { theme } from '../../../store/theme';
import { useSectionOffsetScroll } from '../../../useSectionOffsetScroll';
import styles from './Clients.module.scss';
import { GAEmit, GAEvents } from '../../../gtagHelper';

const Clients = ({ data }: { data: IPartnersComponent }) => {
  const contentOffset = useSectionOffsetScroll('content');
  const appTheme = useAtomValue(theme);
  const deviceSize = useAtomValue<ScreenSize>(clientDeviceSize);
  const setContactsModalTrigger = useSetAtom(contactModalTriggerButton);

  const handleClientButtonClick = (event: SyntheticEvent) => {
    setContactsModalTrigger(`${(event.target as HTMLElement).innerText} | Partners section`);
    GAEmit(GAEvents.becomeClientClick);
  };

  let divideCoefficient = deviceSize === ScreenSize.MOBILE ? 8 : 4;

  return (
    <section
      className={styles.clients}
    >
      <header className={classnames(styles.header, 'container')}>
        <h2 className={classnames('sectionTitle', styles.title)}>
          <AnimatedTitle title={data.title} />
        </h2>
        <div className={styles.divider} />
        <Button
          variant={ButtonVariants.OUTLINED}
          className={styles.ctaButton}
          icon={(
            <svg>
              <use
                xlinkHref='/media/icon-nest.svg#iconNest'
                href='/media/icon-nest.svg#iconNest'
              />
            </svg>
          )}
          iconPosition='right'
          iconSize={{ width: 24, height: 24 }}
          size={deviceSize <= ScreenSize.DESKTOP_SMALL ? 'normal' : 'big'}
          onClick={handleClientButtonClick}
        >
          Become a Client
        </Button>
      </header>
      <div
        className={styles.content}
        id='content'
      >
        {data?.partners?.length > 0 && data.partners.map((partnerRow, index) => (
          <div
            key={partnerRow[0].id}
            className={classnames(styles.row, {
              [styles.row_even]: index % 2 !== 0,
            })}
            style={
              {
                marginLeft: index % 2 === 0
                  ? `${contentOffset / divideCoefficient}px`
                  : `${-contentOffset / divideCoefficient}px`,
                marginRight: index % 2 === 0
                  ? `${-contentOffset / divideCoefficient}px`
                  : `${contentOffset / divideCoefficient}px`,
              }
            }
          >
            {partnerRow.map(partner => (
              <div
                className={styles.item}
                key={partner.id}
              >
                <Img
                  src={partner.image[appTheme].url}
                  alt={partner.image[appTheme].alternativeText || 'partner image'}
                  width={90}
                  height={40}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
      <Button
        variant={ButtonVariants.OUTLINED}
        className={classnames(styles.ctaButton, styles.ctaButton_mobile)}
        icon={(
          <svg>
            <use
              xlinkHref='/media/icon-nest.svg#iconNest'
              href='/media/icon-nest.svg#iconNest'
            />
          </svg>
        )}
        iconPosition='right'
        iconSize={{ width: 24, height: 24 }}
        onClick={(e) => {
          setContactsModalTrigger(`${(e.target as HTMLElement).innerText} | Partners section`);
        }}
      >
        Become a Client
      </Button>
    </section>
  );
};

export default Clients;
