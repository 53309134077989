'use client';

import { ITeamComponent } from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';
import React from 'react';
import { useAtomValue } from 'jotai';
import AnimatedTitle from '../../components/AnimatedTitle';
import styles from './Subservice.module.scss';
import TeamMember from './TeamMember';
import { Theme, theme } from '../../store/theme';

const Team = ({ data }: { data: ITeamComponent }) => {
  const appTheme = useAtomValue<Theme>(theme);
  return (
    <section className={styles.team}>
      <div className='container'>
        <header className={styles.team__header}>
          <h2 className={classnames(styles.team__title, 'title3')}>
            <AnimatedTitle title={data.title} />
          </h2>
          <p className={styles.team__subtitle}>We have multiple roles within our team for your blockchain project.</p>
        </header>
      </div>
      <div className={styles.team__members}>
        {data.team.members.map((member) => (
          <TeamMember
            key={member.id}
            image={member.image}
            name={member.name}
            position={member.position}
          />
        ))}
      </div>
      <div className='container'>
        <div className={styles.team__cards}>
          {data.expertises.map((item, index) => (
            <div
              className={styles.team__card}
              key={item.id}
            >
              <div className={styles.team__card__image}>
                <img
                  src={`/media/industry_geometry/Geometry_${(index + 1) % 5 || 5}_${appTheme}.svg`}
                  alt='team card illustration'
                />
              </div>
              <p
                className={styles.team__card__text}
              >
                {item.text}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Team;
