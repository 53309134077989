import { ContactFormExpert } from '@sapientpro/sapientpro-data-models/types/components/shared/contact-form-expert';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { contactExpert } from './store/contactForm';

const useContactExpert = (expert: ContactFormExpert | null) => {
  const setContactExpert = useSetAtom(contactExpert);

  useEffect(() => {
    setContactExpert(expert || null);

    return () => setContactExpert(null);
  }, [expert]);
};

export default useContactExpert;
