import { useAtomValue } from 'jotai';
import React from 'react';
import { IImageSvg } from '@sapientpro/sapientpro-data-models';
import { theme, Theme } from '../../store/theme';
import styles from './HowWeWorkItem.module.scss';

type HowWeWorkItemProps = {
  icon: IImageSvg,
  title: string,
  text: string,
};
const HowWeWorkItem = ({ icon, title, text }: HowWeWorkItemProps) => {
  const appTheme = useAtomValue<Theme>(theme);

  return (
    <div className={styles.item}>
      <div className={styles.icon}>
        <img
          src={icon[appTheme].url}
          alt={icon[appTheme].alternativeText || 'how we work icon'}
        />
      </div>
      <div>
        <h5 className={styles.title}>
          .
          {title}
        </h5>
        <p className={styles.text}>{text}</p>
      </div>
    </div>
  );
};

export default HowWeWorkItem;
