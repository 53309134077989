'use client';

import {
  CaseListItem, CaseServiceComponent, ICaseResponse, ICasesListResponse,
} from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import AnimatedTitle from '../../../components/AnimatedTitle';
import Button, { ButtonVariants } from '../../../components/Button';
import PostFilters from '../../../components/PostFilters';
import CaseCard from '../../Cases/CaseCard';
import CaseItem from './CaseItem';
import styles from './CasesBlock.module.scss';
import client from '../../../apiClient';

const getCasesByCategory = async (category: string) => {
  try {
    return await client.get<ICasesListResponse>(`cases/list?serviceSlug=${category}`);
  } catch (e) {
    console.error(e.message);
    throw new Error(e);
  }
};

const DynamicCasesBlock = ({ data }: { data: CaseServiceComponent }) => {
  const [filteredCases, setFilteredCases] = useState<CaseListItem[] | ICaseResponse[] | undefined>(data?.cases);
  const [filteredCategory, setFilteredCategory] = useState<string | undefined>(undefined);

  useEffect(() => {
    const fetchCases = async (category: string) => {
      const response = await getCasesByCategory(category);
      setFilteredCases(response.cases);
    };
    if (filteredCategory) {
      fetchCases(filteredCategory);
    }
  }, [filteredCategory]);
  return (
    <section
      className={classnames({
        [styles.cases]: !data.detailed,
        [styles.relatedCases]: data.detailed,
      })}
    >
      <div className='container'>
        <header className={styles.cases__header}>
          <h2 className={classnames('sectionTitle', styles.title)}>
            <AnimatedTitle title={data.title} />
          </h2>
          <p className={styles.cases__subtitle}>{data.description}</p>
        </header>
        {!data.detailed && data.services && (
          <PostFilters
            useLinks={false}
            className={styles.filters}
            filters={data.services}
            activeFilter={filteredCategory}
            clearFilter='All'
            clearCallback={() => {
              setFilteredCategory(undefined);
              setFilteredCases(data.cases);
            }}
            callback={(filterSlug) => {
              setFilteredCategory(filterSlug);
            }}
          />
        )}
        {data.detailed ? (
          <div className={styles.relatedCases__content}>
            {filteredCases?.map((caseItem) => (
              <CaseCard
                data={caseItem}
                key={caseItem.id}
              />
            ))}
          </div>
        ) : (
          <div className={styles.content}>
            {filteredCases?.map((caseItem) => (
              <React.Fragment key={caseItem.id}>
                <CaseItem caseItem={caseItem} />
              </React.Fragment>
            ))}
          </div>
        )}

        <Button
          link='/cases'
          className={styles.viewAllButton}
          variant={ButtonVariants.TRANSPARENT}
          icon={(
            <svg>
              <use
                xlinkHref='/media/icon-nest.svg#iconNest'
                href='/media/icon-nest.svg#iconNest'
              />
            </svg>
          )}
          iconSize={{ width: 24, height: 24 }}
        >
          View All
        </Button>
      </div>
    </section>
  );
};

export default DynamicCasesBlock;
