import classNames from 'classnames';
import styles from './FramedImages.module.scss';

const PhoneClip = ({
  imageUrl,
  className,
}: {
  imageUrl: string;
  className?: string;
}) => (
  <div
    className={classNames(styles.phoneClip, className)}
  >
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 83 178'
      fill='none'
      id={imageUrl}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        // eslint-disable-next-line max-len
        d='M64.1102 2.11819C64.1102 4.21526 62.567 6.77933 59.0162 6.77933H23.6498C20.0991 6.77933 18.5559 4.21526 18.5559 2.19102C18.5559 1.18196 18.5559 0.224609 16.8822 0.224609H9.82343C3.9243 0.224609 0.363281 3.78855 0.363281 9.69253V168.025C0.363281 173.929 3.9243 177.493 9.82343 177.493H72.7698C78.669 177.493 82.23 173.929 82.23 168.025V9.69253C82.23 3.78855 78.669 0.224609 72.7698 0.224609H65.7839C64.1102 0.224609 64.1102 1.18196 64.1102 2.11819Z'
        fill={`url(#pattern0${imageUrl})`}
      />
      <defs>
        <pattern
          id={`pattern0${imageUrl}`}
          patternContentUnits='objectBoundingBox'
          width='1'
          height='1'
        >
          <use
            xlinkHref={`#image0_14335_19464${imageUrl}`}
            transform='matrix(0.00135843 0 0 0.000627353 -0.0107687 0)'
          />
        </pattern>
        <image
          id={`image0_14335_19464${imageUrl}`}
          width='752'
          height='1594'
          xlinkHref={imageUrl}
        />
      </defs>
    </svg>
  </div>
);

export default PhoneClip;
