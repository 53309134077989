'use client';

import { ISapients } from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';

import { useAtomValue, useSetAtom } from 'jotai';
import React, { SyntheticEvent, useRef } from 'react';
// @ts-ignore there are no types for this library
import Parallax, { Layer } from 'react-parallax-scroll';
import AnimatedTitle from '../../../components/AnimatedTitle';
import Button, { ButtonVariants } from '../../../components/Button';
import Img from '../../../components/Img';
import contactModalTriggerButton from '../../../store/contactForm';
import clientScreenSize, { ScreenSize } from '../../../store/deviceScreenSize';
import { useSectionOffsetScroll } from '../../../useSectionOffsetScroll';
import styles from './About.module.scss';
import { GAEmit, GAEvents } from '../../../gtagHelper';

const ParallaxImage = ({ image }: { image: { url: string, alternativeText: string } }) => {
  const deviceSize = useAtomValue<ScreenSize>(clientScreenSize);

  return (
    deviceSize <= ScreenSize.TABLET_PORTRAIT ? (
      <div
        className={styles.about__photo}
      >
        <Img
          src={image?.url}
          alt={image?.alternativeText}
          withPreloader
        />
      </div>
    ) : (
      <Parallax>
        <Layer settings={{ speed: 0.5, type: 'translateY' }}>
          <div
            className={styles.about__photo}
          >
            <Img
              src={image?.url}
              alt={image?.alternativeText}
              withPreloader
            />
          </div>
        </Layer>
      </Parallax>
    )
  );
};

const MAX_PHOTO_MARGIN = 80;
const VIEWPORT_PADDING = 300; // wait when block will be visible at least 300px
const About = ({ data }: { data: ISapients }) => {
  // const photosRef = useRef<HTMLDivElement>(null);
  const sectionRef = useRef<HTMLDivElement>(null);
  // const photosOffset = useSectionOffsetScroll(photosRef);
  const photosOffset = useSectionOffsetScroll('photos');
  const photoMargin = photosOffset - VIEWPORT_PADDING >= MAX_PHOTO_MARGIN ? MAX_PHOTO_MARGIN : photosOffset - VIEWPORT_PADDING;
  const deviceSize = useAtomValue<ScreenSize>(clientScreenSize);
  const setContactsModalTrigger = useSetAtom(contactModalTriggerButton);

  const handleBoostButtonClick = (event: SyntheticEvent) => {
    setContactsModalTrigger(`${(event.target as HTMLElement).innerText} | About section`);
    GAEmit(GAEvents.boostProjectClick);
  };

  return (
    <section
      className={styles.about}
      ref={sectionRef}
    >
      {data.showImage && (
        <ParallaxImage image={{ url: data?.image?.url, alternativeText: data?.image?.alternativeText || '' }} />
      )}
      <div className={classnames(styles.about__content, {
        [styles.about__content_top]: !data.showImage,
      })}
      >
        <header className={classnames(styles.header, 'container')}>
          <h2 className={classnames('sectionTitle', styles.title)}>
            <AnimatedTitle title={data?.title} />
          </h2>
          <p className={styles.text}>
            {data?.description}
          </p>
        </header>
        <div className={styles.photos__wrap}>
          <div
            className={styles.photos}
            // ref={photosRef}
            id='photos'
          >
            {data?.collage?.map(({ url, alternativeText, id }, index) => (
              <div
                key={id}
                className={styles.photo}
                style={index % 2 === 0 && photosOffset > VIEWPORT_PADDING ? { marginTop: `${40 - photoMargin}px` } : {}}
              >
                <Img
                  src={url}
                  alt={alternativeText || 'collage image'}
                  withPreloader
                />
              </div>
            ))}
          </div>
        </div>
        <div className={classnames(styles.textContent, 'container')}>
          <h3 className={styles.slogan}>
            {data?.bottomQuote}
          </h3>
          <p className={styles.text}>
            {data?.bottomDescription}
          </p>
        </div>
        <Button
          variant={ButtonVariants.OUTLINED}
          className={styles.ctaButton}
          icon={(
            <svg>
              <use
                xlinkHref='/media/icon-nest.svg#iconNest'
                href='/media/icon-nest.svg#iconNest'
              />
            </svg>
          )}
          iconPosition='right'
          iconSize={deviceSize <= ScreenSize.DESKTOP_SMALL ? { width: 24, height: 24 } : { width: 32, height: 32 }}
          size={deviceSize <= ScreenSize.DESKTOP_SMALL ? 'normal' : 'big'}
          onClick={handleBoostButtonClick}
        >
          Boost your project
        </Button>
      </div>
      <div className={styles.blurBg} />
    </section>
  );
};

export default About;
