'use client';

import { IAchievement, IAchievementCardComponent } from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';
import { useSetAtom } from 'jotai';
import React from 'react';
import Ellipse, { EllipseVariants } from '../../../../components/Ellipse';
import contactModalTriggerButton from '../../../../store/contactForm';
import styles from './StatsBlock.module.scss';

const StatTile = ({ title, scope }: Pick<IAchievement, 'title' | 'scope'>) => (
  <div className={styles.tile}>
    <div
      className={classnames(styles.icon)}
    >
      <Ellipse
        variant={EllipseVariants.NOISE}
        size={18}
      />
    </div>
    <div>
      <p className={styles.tile__title}>{title}</p>
      <p className={styles.tile__value}>{scope}</p>
    </div>
  </div>
);

type StatsBlockProps = {
  data: IAchievementCardComponent
};

const StatsBlock = ({ data }: StatsBlockProps) => {
  const setContactsModalTrigger = useSetAtom(contactModalTriggerButton);

  return (
    <section className={classnames(styles.stats, 'container')}>
      <h4 className={classnames('title4', styles.title)}>{data.title}</h4>
      <div className={styles.stats__content}>
        {data.achievementCards.slice(0, -1).map(item => (
          <StatTile
            key={item.id}
            title={item.title}
            scope={item.scope}
          />
        ))}
        <button
          type='button'
          onClick={(e) => {
            setContactsModalTrigger(`${(e.target as HTMLElement).innerText} | Stats section`);
          }}
          className={classnames(styles.tile, styles.startProjectButton)}
        >
          <div
            className={classnames(styles.icon)}
          >
            <svg>
              <use
                xlinkHref='/media/icon-nest.svg#iconNest'
                href='/media/icon-nest.svg#iconNest'
              />
            </svg>
          </div>
          <p className={styles.startProjectButton__title}>Start your project! </p>
        </button>
        {data.achievementCards.slice(-1).map(item => (
          <StatTile
            key={item.title}
            title={item.title}
            scope={item.scope}
          />
        ))}
      </div>
    </section>
  );
};

export default StatsBlock;
