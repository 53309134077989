'use client';

import { ICaseResponse, ICasesComponent } from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';
import React, { useState } from 'react';
import AnimatedTitle from '../../../components/AnimatedTitle';
import Button, { ButtonVariants } from '../../../components/Button';
import PostFilters from '../../../components/PostFilters';
import CaseCard from '../../Cases/CaseCard';
import CaseItem from './CaseItem';
import styles from './CasesBlock.module.scss';

const CasesBlock = ({ data }: { data: ICasesComponent }) => {
  const [filteredCases, setFilteredCases] = useState<ICaseResponse[] | undefined>(data.cases);
  const [filteredCategory, setFilteredCategory] = useState<string | undefined>(undefined);
  const filterCases = (slug: string) => (
    setFilteredCases(data.cases.filter(caseItem => caseItem.subservices.find(subservice => subservice?.service?.slug === slug)))
  );
  return (
    <section className={classnames({
      [styles.cases]: !data.detailed,
      [styles.relatedCases]: data.detailed,
    })}
    >
      <div className='container'>
        <header className={styles.cases__header}>
          <h2 className={classnames('sectionTitle', styles.title)}>
            <AnimatedTitle title={data.title} />
          </h2>
          <p className={styles.cases__subtitle}>
            {data.description}
          </p>
        </header>
        {!data.detailed && (
          <PostFilters
            className={styles.filters}
            filters={data.categories}
            activeFilter={filteredCategory}
            clearFilter='All'
            clearCallback={() => {
              setFilteredCategory(undefined);
              setFilteredCases(data.cases);
            }}
            callback={(filterSlug) => {
              setFilteredCategory(filterSlug);
              filterCases(filterSlug);
            }}
          />
        )}
        {data.detailed ? (
          <div className={styles.relatedCases__content}>
            {filteredCases?.map(caseItem => (
              <CaseCard
                data={caseItem}
                key={caseItem.id}
              />
            ))}
          </div>
        ) : (
          <div className={styles.content}>
            {filteredCases?.map(caseItem => (
              <React.Fragment key={caseItem.id}>
                <CaseItem
                  caseItem={caseItem}
                />
              </React.Fragment>
            ))}
          </div>
        )}

        <Button
          link='/cases'
          className={styles.viewAllButton}
          variant={ButtonVariants.TRANSPARENT}
          icon={(
            <svg>
              <use
                xlinkHref='/media/icon-nest.svg#iconNest'
                href='/media/icon-nest.svg#iconNest'
              />
            </svg>
          )}
          iconSize={{ width: 24, height: 24 }}
        >
          View All
        </Button>
      </div>
    </section>
  );
};

export default CasesBlock;
