import Img from '../../components/Img';
import styles from './Subservice.module.scss';

type TeamMemberProps = {
  image: {
    url: string,
    alternativeText: string | null,
  },
  name: string,
  position?: string,
};
const TeamMember = ({ image, name, position }: TeamMemberProps) => (
  <div className={styles.teamMember}>
    <div className={styles.teamMember__photo}>
      <Img
        src={image.url}
        alt={image.alternativeText || name}
      />
    </div>
    <p className={styles.teamMember__name}>{name}</p>
    {position && <p className={styles.teamMember__position}>{position}</p>}
  </div>
);

export default TeamMember;
