import { atom } from 'jotai';

export enum Section {
  FIRST = 1,
  SECOND = 2,
  THIRD = 3,
  FOURTH = 4,
}

const activeHomeSection = atom<Section>(Section.FIRST);
export const prevActiveHomeSection = atom<Section | undefined>(undefined);

export default activeHomeSection;
